const _file = 'Autocomplete';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var Autocomplete = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var $selects_to_auto = $(element).find(".s-to-autocomplete");
    if ($selects_to_auto.length > 0) {
      $selects_to_auto.each(function () {
        $(this).selectToAutocomplete({'copy-attributes-to-text-field': true, autoFocus: !$(this).hasClass('select-tags') });
      });

      setTimeout(function () {
        $selects_to_auto.each(function () {
          if ($(this).attr('id')) {
            $('.ui-autocomplete').addClass($(this).attr('id'));
          }
          if ($(this).parent().attr('id')) {
            var $autocomplete_input = $(this).parent().find('input.ui-autocomplete-input')
              $autocomplete_input.autocomplete("option", "appendTo", "#" + $(this).parent().attr('id') );
          }
          else {
            console.log("WARNING: autocomplete parent() element needs an attr('id') in order to append the menu");
            console.log("WARNING: this is for name='" + $(this).attr('name') + "' id='" + $(this).attr('id') + "'");
          }
        });
      }, 100);
    }
  });

  //Return API for other modules
  return {};
})(jQuery);
